<template>
    <div class="page_container">
        <div class="page_head">
            <router-link to="/new_campaign">
                <div class="back_btn">
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 13.0586L2 7.03909L8 1.01954" stroke="#333333" stroke-width="2"/>
                    </svg>
                    Campaign name
                </div>
            </router-link>
<!--            <b-button class="add_btn">+ Add new</b-button>-->
        </div>
        <div class="page_content">
            <div class="share_btn-container">
                <b-button class="share_btn">
                    <div class="share_icon"></div>
                    Share campaign
                </b-button>
            </div>
            <b-tabs content-class="mt-3">
                <b-tab title="Leads" active>
                    <div class="page_tab-content leads">
                        <div class="leads_content">
                            <div class="leads_head">
                                <div class="input_item">
                                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M16.5 16.5L12.875 12.875M14.8333 8.16667C14.8333 11.8486 11.8486 14.8333 8.16667 14.8333C4.48477 14.8333 1.5 11.8486 1.5 8.16667C1.5 4.48477 4.48477 1.5 8.16667 1.5C11.8486 1.5 14.8333 4.48477 14.8333 8.16667Z"
                                              stroke="#333333" stroke-width="1.66667" stroke-linecap="round"
                                              stroke-linejoin="round"/>
                                    </svg>
                                    <b-form-input
                                            v-model="search_term"
                                            placeholder="Search"
                                    ></b-form-input>
                                </div>
                                <b-button class="upload_btn" @click="csvUpload()">
                                    Upload CSV
                                    <input type="file" ref="csv" @change="onFileChange"/>
                                </b-button>
                            </div>
                            <div class="leads_data">
                                <b-table hover :items="filteredItems" :fields="fields">
                                    <template v-slot:cell(name)="data">
                                        {{ data.item.first_name }}
                                    </template>
                                    <template v-slot:cell(email)="data">
                                        {{ data.item.ig_handle }}
                                    </template>
                                    <template v-slot:cell(line)="data">
                                        {{ data.item.personal_line }}
                                    </template>
                                </b-table>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Schedule">
                    <div class="page_tab-content">
                        <div class="schedule_content">
                            <div class="schedule_img"></div>
                            <div class="input_item">
                                <label>Choose a date for picking</label>
                                <b-form-input
                                        type="date"
                                        v-model="schedule.date"
                                        placeholder="dd-mm-yyyy"
                                ></b-form-input>
                                <span v-if="errors['date_for_picking']" class="text_danger">{{errors['date_for_picking'][0]}}</span>
<!--                                <div class="add_new-container">-->
<!--                                    <div class="add_new-btn" @click="addNew()">+Add new</div>-->
<!--                                </div>-->
                            </div>
                            <div class="input_item">
                                <label>Time delay between messages</label>
                                <div class="sub_title">How much maximum per day</div>
                                <b-form-input
                                        type="number"
                                        v-model="schedule.per_day"
                                ></b-form-input>
                                <span v-if="errors['delay_between_messages']" class="text_danger">{{errors['delay_between_messages'][0]}}</span>


                            </div>
                            <div class="input_item">
                                <label>Choose Day</label>
                                <b-form-group class="input_checkbox mb-0" v-slot="{ ariaDescribedby }">
                                    <b-form-checkbox-group
                                            id="checkbox-group-2"
                                            v-model="schedule.selectedDays"
                                            :aria-describedby="ariaDescribedby"
                                            name="flavour-2"
                                    >
                                        <b-form-checkbox value="0">Monday</b-form-checkbox>
                                        <b-form-checkbox value="1">Tuesday</b-form-checkbox>
                                        <b-form-checkbox value="2">Wednesday</b-form-checkbox>
                                        <b-form-checkbox value="3">Thursday</b-form-checkbox>
                                        <b-form-checkbox value="4">Friday</b-form-checkbox>
                                        <b-form-checkbox value="5">Saturday</b-form-checkbox>
                                        <b-form-checkbox value="6">Sunday</b-form-checkbox>
                                    </b-form-checkbox-group>
                                </b-form-group>
                                <span v-if="errors['day']" class="text_danger">{{errors['day'][0]}}</span>

                            </div>
                            <div class="save_btn-container">
                                <b-button class="save_btn my-1" @click="saveDetails()">Save Details</b-button>
                                <span v-if="errors['account']" class="text_danger">{{errors['account']}}</span>
                            </div>
                        </div>
                        <div class="accounts_content">
                            <div class="accounts_item" v-for="(item, index) in accounts" :index="index">
                                <div class="accounts_item-user">
                                    <div class="accounts_item-img"
                                         :style="{ backgroundImage: 'url(' + item.media_url + ')' }">
                                        <div class="instagram_logo"></div>
                                    </div>
                                    <div class="accounts_item-name">{{item.user_name}}</div>
                                </div>
                                <div class="accounts_item-switch">
                                    <b-form-checkbox
                                            switch
                                            size="lg"
                                            v-model="item.status"
                                            value="1"
                                            unchecked-value="0"
                                            @change="changeStatus(item, index)"
                                    ></b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Sequences" :disabled="!active_account">
                    <div class="page_tab-content sequences">
                        <div class="sequences_step">
                            <div v-if="update_message" class="updated_text">{{update_message}}</div>
                            <div class="sequences_step-item"
                                 v-for="(step, index) of steps"
                                 :key="index"
                                 :class="{'active': step.is_active == '1'}"
                                 @click="activeTemplate(index)">
                                <div class="step_head">
                                    <div class="step_title"><div class="step_icon"></div>&nbsp; Step {{index + 1}}</div>
                                    <div class="step_actions">
                                        <div class="step_remove-btn" @click="removeStep(index)"></div>
<!--                                        <div class="step_settings-btn"></div>-->
                                    </div>
                                </div>
                                <b-form-input placeholder="Subject" v-model="steps[index].subject" class="step_input"></b-form-input>
<!--                                <div class="step_add-variant">+Add variant</div>-->
                                <div class="step_day-container">
                                    <div class="step_day">Wait for</div>
                                    <b-form-input type="number" v-model="steps[index].wait_for" class="step_day-input"></b-form-input>
                                    <div class="step_day">days, then</div>
                                </div>
                                <b-button class="step_save-btn" @click="saveTemplate(index)">Save as a template</b-button>
                            </div>
                            <b-button class="sequences_step-btn" @click="addStep()">+Add step</b-button>
                        </div>
                        <div class="sequences_content">
                            <div class="sequences_head">
                                <div class="sequences_head-title" v-if="active_template">{{active_template.subject}}</div>
                            </div>
                            <vue-editor :disabled="!active_template" placeholder="Start typing here..." v-model="content" />
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Analytics">
                    <div class="page_tab-content analytics">
                        <div class="analytics_stats">
                            <div class="analytics_stats-content">
                                <h4 class="analytics_stats-title">Campaign stats</h4>
                                <div class="input_item">
                                    <b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
                                        <template #button-content>
                                            <div class="filter_dropdown">
                                                <div class="filter_icon"></div>
                                                {{getActiveStats(active_stats)}}
                                            </div>
                                        </template>
                                        <b-dropdown-item @click="active_stats = ''">All time</b-dropdown-item>
                                        <b-dropdown-item @click="active_stats = '1'">Today</b-dropdown-item>
                                        <b-dropdown-item @click="active_stats = '7'">Last week</b-dropdown-item>
                                        <b-dropdown-item @click="active_stats = '4'">Last 4 weeks</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                            <div class="analytics_stats-data">
                                <div class="analytics_stats-icon"></div>
                                Unread
                            </div>
                        </div>
                        <div class="analytics_data"></div>
                    </div>
                </b-tab>
            </b-tabs>
        </div>
<!--subscription modal-->
        <b-modal ref="subscription-modal" centered size="md" hide-header hide-footer>
            <div class="modal_content">
                <div class="modal_title">
                    Unlock premium Features
                </div>
                <div class="modal_subtitle">Upgrade to hyper Growth to unlock this feature</div>

                <div class="modal_btn">
                    <b-button class="cancel_btn mx-2" @click="subscriptionModal()">Cancel</b-button>
                    <b-button class="change_btn mx-2" @click="upgradePlan()">Upgrade plan</b-button>
                </div>

            </div>
        </b-modal>
<!--subscription modal-->
        <!--        <div>From store_ {{campaignName}}</div>-->
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "CampaignView",
        data() {
            return {
                search_term: '',
                uploadFile: null,
                csvItems: [],
                schedule: {
                    account_id: null,
                    campaign_name: null,
                    date: null,
                    per_day: null,
                    selectedDays: [],
                },
                accounts: [],
                fields: [
                    {
                        key: 'name',
                        label: 'First name',
                    },
                    {
                        key: 'email',
                        label: 'IG Handle',
                    },
                    {
                        key: 'line',
                        label: 'Personal Line',
                    }
                ],
                active_stats: '1',
                content: null,
                steps: [
                    {
                        subject: null,
                        wait_for: null,
                        text: null
                    }
                ],
                active_template: null,
                active_account: null,
                update_message: null,
                errors: [],
            }
        },
        created() {
            this.getAllLeads()
                .then(res => {
                    this.csvItems = res.data
                })
            this.getTemplates()
            .then(res => {
                this.steps = res.data
            })
        },
        computed: {
            ...mapGetters(['campaignName', 'logUserData']),
            filteredItems() {
                var self = this;
                return this.csvItems.filter(function (item) {
                    return item.first_name.toLowerCase().indexOf(self.search_term.toLowerCase()) >= 0
                        || item.ig_handle.toLowerCase().indexOf(self.search_term.toLowerCase()) >= 0
                        || item.personal_line.toLowerCase().indexOf(self.search_term.toLowerCase()) >= 0;
                });
            }
        },
        mounted() {
            setTimeout(() => {
                this.getAllAccounts()
                    .then(res => {
                        this.accounts = res.data
                    })
                if(this.logUserData.subscription_type == 'core') {
                    this.subscriptionModal()
                }
            }, 1000)
            this.steps.forEach((item) => {
                item.status = null
            })


        },
        methods: {
            ...mapActions([
                'getAllLeads',
                'uploadCSV',
                'getAllAccounts',
                'addNewCampaign',
                'getTemplates',
                'addNewTemplate',
                'removeTemplate',
                'updateTemplate'
            ]),
            csvUpload() {
                this.$refs.csv.click()
            },
            onFileChange(event) {
                this.uploadFile = event.target.files[0]
                this.uploadCSV(this.uploadFile)
                    .then(res => {
                        console.log(res, 'res')
                    })
            },
            // addNew() {
            //     console.log('add new')
            // },
            saveDetails() {
                this.errors = []
                this.schedule.campaign_name = this.campaignName
                this.accounts.forEach(item => {
                    if(item.status == '1') {
                        this.schedule.account_id = item.user_id
                        this.addNewCampaign(this.schedule)
                            .then(res => {
                                if(res.status == 200) {
                                    this.$router.push('/campaign')
                                } else {
                                    this.errors = res.response.data.error
                                }
                            })
                    } else {
                        this.errors['account'] = 'Please add account'
                    }

                })
                // this.addNewCampaign(this.schedule)
                //     .then(res => {
                //         console.log(res, 'res')
                //     })
            },
            changeStatus(event, i) {
                this.accounts.forEach((item, index) => {
                    if(index == i) {
                        item.status = '1'
                        this.active_account = item.id
                    } else {
                        item.status = '0'
                    }
                })
            },
            getActiveStats(stats) {
                switch (stats) {
                    case '':
                        return 'All time';
                    case '1':
                        return 'Today';
                    case '7':
                        return 'Last week';
                    case '4':
                        return 'Last 4 weeks';
                }
            },
            activeTemplate(i) {
                this.steps.forEach((item, index) => {
                    if(i == index) {
                        item.is_active = '1'
                        this.active_template = item
                        this.content = item.text
                    } else {
                        item.is_active = '0'
                    }
                })
                // event.target.className += " active";
            },
            saveTemplate(i) {
                this.steps.forEach((item, index) => {
                    if(i == index) {
                        if(this.active_template.id) {
                            let send_data = {
                                account_id: item.id,
                                subject: item.subject,
                                wait_for: item.wait_for,
                                text: this.content
                            }
                            this.updateTemplate(send_data)
                            .then(res => {
                                this.update_message = 'Template Updated'
                                setTimeout(() => {
                                    this.update_message = null
                                }, 3000)
                                this.content = null
                            })
                        } else {
                            let send_data = {
                                account_id: this.active_account,
                                subject: item.subject,
                                wait_for: item.wait_for,
                                text: this.content
                            }
                            this.addNewTemplate(send_data)
                                .then(res => {
                                    this.update_message = 'New Template Created'
                                    this.content = null
                                    console.log(res)
                                })
                        }
                    }
                })
            },
            addStep() {
                this.steps.push(
                    {
                        subject: null,
                        wait_for: null,
                        text: null,
                        is_active: 0,
                        account_id: null
                    }
                )
            },
            removeStep(i) {
                this.steps.forEach((item, index) => {
                    if(i == index) {
                        this.steps.splice(i, 1)
                        if(item.id) {
                            this.removeTemplate(item.id)
                        }

                    }
                })
            },
            subscriptionModal() {
                this.$refs['subscription-modal'].toggle('#subscribe-btn')
            },
            upgradePlan() {
                this.$router.push('/settings')
            }
        }
    }
</script>

<style scoped lang="scss">
    input[type="date"]::-webkit-calendar-picker-indicator {
        width: 24px;
        height: 24px;
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
        cursor: pointer;
    }

    input[type="date"]::-webkit-calendar-picker-indicator:hover {
        background: url("~@/assets/images/calendar.svg") center/80% no-repeat;
    }

    .page {
        &_container {
            padding: 8px 0;
            display: flex;
            flex-direction: column;
        }

        &_content {
            & .tabs {
                width: 100%;
                padding-right: 100px;
                @media screen and (max-width: 1024px) {
                    padding-right: 30px;
                }
            }

            & .share {
                &_btn {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 10px;
                    width: 175px;
                    height: 44px;
                    background: #ffffff;
                    border: 1px solid #1890FF;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 21px;
                    color: #1890FF;

                    &:hover {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }

                    &:active {
                        background: #ffffff;
                        color: #1890FF;
                    }

                    &:focus {
                        filter: none;
                        outline: none;
                        box-shadow: none;
                        border: 1px solid #0E78DB;
                    }

                    &-container {
                        padding-top: 25px;
                        height: 30px;
                        padding-right: 100px;
                        display: flex;
                        justify-content: flex-end;
                        @media screen and (max-width: 1024px) {
                            padding-right: 30px;
                        }

                    }
                }

                &_icon {
                    width: 24px;
                    height: 24px;
                    background: url("~@/assets/images/share.svg");
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            }
        }

        &_head {
            padding-right: 100px;
            display: flex;
            justify-content: space-between;
            @media screen and (max-width: 1024px) {
                padding-right: 30px;
            }

            & .back_btn {
                padding: 0 5px;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #000000;

                & svg {
                    margin-right: 5px;
                }
            }

            & .add_btn {
                width: 130px;
                height: 50px;
                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                border-radius: 10px;
                border: none;

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    background: #8103F6;
                }
            }
        }

        &_tab {
            &-content {
                display: flex;

                &.leads {
                    padding: 10px 20px;
                    background: #ffffff;
                    border: 1px solid #E7E7E7;
                    border-radius: 8px;
                }

                & .leads {
                    &_content {
                        display: flex;
                        flex-direction: column;
                    }

                    &_head {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        & .input_item {
                            position: relative;
                            width: 320px;
                            margin-right: 16px;

                            & svg {
                                position: absolute;
                                top: 14px;
                                left: 16px;
                            }

                            & input {
                                padding-left: 45px;
                                height: 44px;
                                outline: none;
                                line-height: 24px;
                                box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

                                &::placeholder {
                                    color: #393939;
                                    line-height: 24px;
                                }
                            }
                        }

                        & .upload_btn {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 10px;
                            width: 130px;
                            height: 40px;
                            background: #ffffff;
                            border: 1px solid #1890FF;
                            border-radius: 8px;
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 21px;
                            color: #1890FF;

                            &:hover {
                                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            }

                            &:focus {
                                filter: none;
                                outline: none;
                                box-shadow: none;
                                border: 1px solid #0E78DB;
                            }

                            & input {
                                width: 0;
                                height: 0;
                            }
                        }
                    }

                    &_data {
                        padding: 58px 0;
                        max-width: 620px;
                        width: 100%;

                        ::v-deep thead tr th {
                            border-top: 1px solid white;
                            border-bottom: transparent;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 24px;
                            color: #000000;
                        }

                        ::v-deep tbody tr td {
                            padding: 0.8rem 0.5rem;
                            font-size: 16px;
                            line-height: 24px;
                            color: #393939;
                        }
                    }

                }

                & .schedule {
                    &_content {
                        width: 540px;
                        /*height: 730px;*/
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        border-radius: 8px;
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        & .input_item {
                            padding: 30px 0 0;
                            width: 100%;

                            & .sub_title {
                                padding-bottom: 8px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 21px;
                                color: #393939;
                            }

                            & label {
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 26px;
                                color: #000000;
                            }

                            & input {
                                width: 100%;
                                height: 50px;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                border-radius: 10px;
                                outline: none;
                                line-height: 24px;
                                box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);

                                &:focus {
                                    border: 1px solid rgba(24, 144, 255, 0.3);
                                    box-shadow: none;
                                }
                            }

                            & .add_new {
                                &-container {
                                    width: 100%;
                                    display: flex;
                                    justify-content: flex-end;
                                }

                                &-btn {
                                    padding-top: 16px;
                                    font-weight: 500;
                                    font-size: 20px;
                                    line-height: 26px;
                                    text-align: center;
                                    color: #1890FF;
                                    cursor: pointer;
                                }
                            }

                            & .input_checkbox {
                                & .custom-control {
                                    margin-right: 12px;
                                    padding-left: 1.3rem;
                                    padding-bottom: 0.5rem;
                                }
                            }
                        }

                        & .save_btn {
                            &-container {
                                display: flex;
                                flex-direction: column;
                                width: 100%;
                            }
                            width: 130px;
                            height: 50px;
                            background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                            border-radius: 10px;
                            border: none;

                            &:hover {
                                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                            }

                            &:focus {
                                background: #8103F6;
                            }
                        }
                    }

                    &_img {
                        width: 185px;
                        height: 170px;
                        background: url("~@/assets/images/date_img.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;
                    }
                }

                &.sequences {
                    display: flex;
                    padding-right: 100px;
                    height: 100%;
                    background: #F8F8F8;
                    @media screen and (max-width: 1024px) {
                        padding-right: 0;
                    }
                    & .sequences {
                        &_step {
                            display: flex;
                            flex-direction: column;
                            margin-right: 30px;
                            @media screen and (max-width: 1024px) {
                                margin-right: 20px;
                            }
                            & .updated_text {
                                text-align: center;
                                color: #00d200;
                                font-weight: 500;
                                font-size: 17px;
                            }
                            &-item {
                                width: 430px;
                                /*height: 360px;*/
                                background: #FFFFFF;
                                border: 1px solid #E7E7E7;
                                border-radius: 8px;
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                padding: 20px;
                                margin-bottom: 30px;
                                @media screen and (max-width: 1024px) {
                                    padding: 15px;
                                    width: 320px;
                                }
                                & .step {
                                    &_head {
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        max-width: 210px;
                                        width: 100%;
                                    }
                                    &_input {
                                        margin: 35px 0;
                                        max-width: 208px;
                                        width: 100%;
                                        border: 1px solid #E7E7E7;
                                        border-radius: 0;
                                        @media screen and (max-width: 1024px) {
                                            margin: 25px 0;
                                        }

                                        &:focus {
                                            border: 1px solid rgba(24, 144, 255, 0.3);
                                            box-shadow: none;
                                        }
                                    }
                                    &_title {
                                        font-weight: 500;
                                        font-size: 20px;
                                        line-height: 26px;
                                        color: #000000;
                                        display: flex;
                                        align-items: center;
                                        @media screen and (max-width: 1024px) {
                                            font-size: 18px;
                                        }

                                    }
                                    &_icon {
                                        width: 25px;
                                        height: 24px;
                                        background: url("~@/assets/images/envelope.svg");
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                    }
                                    &_remove-btn {
                                        width: 25px;
                                        height: 24px;
                                        background: url("~@/assets/images/trash_light.svg");
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        margin: 0 5px;
                                        cursor: pointer;

                                    }
                                    &_settings-btn {
                                        width: 24px;
                                        height: 24px;
                                        background: url("~@/assets/images/settings_black.svg");
                                        background-position: center;
                                        background-repeat: no-repeat;
                                        background-size: contain;
                                        margin: 0 5px;
                                        cursor: pointer;
                                    }
                                    &_actions {
                                        display: flex;
                                    }
                                    &_add-variant {
                                        font-weight: 500;
                                        font-size: 16px;
                                        line-height: 21px;
                                        cursor: pointer;
                                        color: #1890FF;
                                    }
                                    &_save-btn {
                                        width: 235px;
                                        height: 45px;
                                        background: #1890FF;
                                        border-radius: 10px;
                                        color: #ffffff;
                                        font-weight: 400;
                                        font-size: 14px;
                                        line-height: 20px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;
                                        border: none;
                                        @media screen and (max-width: 1024px) {
                                            width: 200px;
                                        }
                                        &:hover {
                                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                        }
                                        &:focus {
                                            filter: none;
                                            outline: none;
                                            box-shadow: none;
                                            border: 1px solid #0E78DB;
                                        }
                                    }
                                    &_day {
                                        font-weight: 500;
                                        font-size: 20px;
                                        line-height: 26px;
                                        color: #000000;
                                        @media screen and (max-width: 1024px) {
                                            font-size: 18px;
                                        }
                                        &-container {
                                            padding: 35px 10px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: space-between;
                                            width: 100%;
                                            @media screen and (max-width: 1024px) {
                                                padding: 30px 10px;
                                            }
                                        }
                                        &-input {
                                            width: 90px;
                                            border: 1px solid #E7E7E7;
                                            border-radius: 0;
                                            &:focus {
                                                border: 1px solid rgba(24, 144, 255, 0.3);
                                                box-shadow: none;
                                            }
                                        }
                                    }
                                }
                                &:hover {
                                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                }
                                &.active {
                                    border: 2px solid #0E78DB;
                                }
                            }
                            &-btn {
                                width: 430px;
                                height: 40px;
                                margin-bottom: 30px;
                                border: 1px solid #1890FF;
                                border-radius: 8px;
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 21px;
                                background: #ffffff;
                                color: #1890FF;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                @media screen and (max-width: 1024px) {
                                    width: 320px;
                                }

                                &:hover {
                                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                }

                                &:active {
                                    background: #ffffff;
                                    color: #1890FF;
                                }

                                &:focus {
                                    filter: none;
                                    outline: none;
                                    box-shadow: none;
                                    border: 1px solid #0E78DB;
                                }


                            }
                        }
                        &_content {
                            width: 100%;
                            height: 430px;
                            background: #FFFFFF;
                            border: 1px solid #1890FF;
                            border-radius: 8px;
                            padding: 20px;

                        }
                        &_head {
                            width: 100%;
                            height: 40px;
                            border-bottom: 1px solid #E7E7E7;
                            border-radius: 8px;
                            display: flex;
                            /*justify-content: flex-end;*/
                            padding-bottom: 5px;
                            &-title {
                                padding: 0 20px;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 26px;
                                color: #000000;
                            }
                            &-btn {
                                width: 120px;
                                height: 45px;
                                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                                border-radius: 10px;
                                border: none;

                                &:hover {
                                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                }

                                &:focus {
                                    background: #8103F6;
                                }

                            }
                        }
                    }
                }

                &.analytics {
                    display: flex;

                    & .analytics {
                        &_stats {
                            width: 386px;
                            height: 120px;
                            background: #FFFFFF;
                            border: 1px solid #E7E7E7;
                            border-radius: 8px;
                            padding: 20px;
                            display: flex;
                            flex-direction: column;

                            &-content {
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                & .input_item {
                                    & .b-dropdown {
                                        width: 160px;
                                        height: 44px;
                                        background: #FFFFFF;
                                        border: 1px solid #E7E7E7;
                                        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
                                        border-radius: 8px;
                                        display: flex;
                                        justify-content: center;
                                        align-items: center;

                                        &:hover {
                                            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                                        }
                                    }

                                    ::v-deep .btn {
                                        &:focus,
                                        &:focus-visible {
                                            box-shadow: none !important;
                                            outline: none !important;
                                        }
                                    }

                                    ::v-deep .filter {
                                        &_dropdown {
                                            display: flex;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 20px;
                                            color: #000000;
                                        }

                                        &_icon {
                                            width: 20px;
                                            height: 20px;
                                            background: url("~@/assets/images/filters_lines.svg");
                                            background-position: center;
                                            background-repeat: no-repeat;
                                            background-size: contain;
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }

                            &-title {
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 26px;
                                color: #000000;

                            }

                            &-data {
                                padding-top: 10px;
                                display: flex;
                                align-items: center;
                                font-weight: 500;
                                font-size: 20px;
                                line-height: 26px;
                                color: #000000;
                            }

                            &-icon {
                                margin-right: 10px;
                                width: 24px;
                                height: 20px;
                                background: url("~@/assets/images/envelope.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: cover;

                            }

                        }
                    }
                }

                & .accounts {
                    &_content {
                        width: 100%;
                        min-height: 160px;
                        padding: 20px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        border-radius: 8px;
                        margin-left: 12px;
                        display: flex;
                        flex-wrap: wrap;
                        align-self: flex-start;
                    }

                    &_item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 390px;
                        height: 96px;
                        background: #FFFFFF;
                        border: 1px solid #E7E7E7;
                        border-radius: 8px;
                        margin: 0 20px 20px 0;
                        padding: 10px;

                        &-user {
                            display: flex;
                            align-items: center;
                        }

                        &-img {
                            position: relative;
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            background: url("~@/assets/images/user_empty_img.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;

                            & .instagram_logo {
                                position: absolute;
                                bottom: 0;
                                right: -6px;
                                width: 24px;
                                height: 24px;
                                background: url("~@/assets/images/instagram_icon.svg");
                                background-position: center;
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }

                        &-name {
                            padding: 0 20px;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            color: #000;
                        }
                    }
                }

            }
        }
    }
    .modal {
        &_content {
            padding: 18px;
        }

        &_title {
            font-weight: 500;
            font-size: 20px;
            line-height: 20px;
            text-align: center;
            color: #000000;
        }

        &_subtitle {
            padding: 24px 0;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #393939;
        }

        &_btn {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 20px;

            & .cancel_btn {
                border: 1px solid rgba(57, 57, 57, 0.5);
                border-radius: 10px;
                height: 50px;
                width: 130px;
                background: #fff;
                color: rgba(57, 57, 57, 0.5);

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    border: 1px solid #393939;
                    color: #393939;
                }
            }

            & .change_btn {
                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                border-radius: 10px;
                height: 50px;
                width: 130px;
                border: none;

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    background: #8103F6;
                    box-shadow: none;
                }

            }
        }

    }

    ::v-deep .nav-tabs {
        & .nav-link {
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
            color: #000;

            &:hover {
                border-color: transparent;
            }

            &.active {
                background-color: transparent;
                border-color: transparent;
                border-bottom: 2px solid #1890FF;
            }
        }
    }

    ::v-deep .is-invalid {
        background-image: none !important;
        border: 1px solid rgba(255, 6, 6, 0.6) !important;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
    ::v-deep .quillWrapper {
        display: flex;
        flex-direction: column-reverse;
        height: calc(100% - 35px);
        & .ql-toolbar.ql-snow,
        & .ql-container.ql-snow {
            border: none;
        }
    }
</style>
