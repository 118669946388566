<template>
    <div class="header_container">
        <div class="header_content">
            <div class="header_user-data">
                <div class="header_user-img">
                    <img :src="logUserData.avatar ? logUserData.avatar: userEmptyImg" alt="">
                </div>
                <div class="header_user-name" @click="openDropdown()">{{logUserData.name}}</div>
                <b-dropdown size="lg" variant="link" right toggle-class="text-decoration-none" no-caret>
                    <template #button-content>
                        <div class="arrow_down" ref="drop"></div>
                    </template>
                    <b-dropdown-item @click="settingsPage()">Settings</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="signOut()">Log Out</b-dropdown-item>
                </b-dropdown>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import userEmptyImg from "@/assets/images/user_empty_img.svg"

    export default {
        name: "Header",
        data() {
            return {
                userData: {
                    fullName: null,
                    avatar: null,
                },
                userEmptyImg: userEmptyImg
            }
        },
        created() {
            this.getUserData()
            .then(res => {
                this.userData.avatar = res.data.avatar
                this.userData.fullName = res.data.name
            })
        },
        computed: {
          ...mapGetters(["logUserData"]),
        },
        methods: {
            ...mapActions(['getUserData']),
            signOut() {
                this.$store.commit('clearUserData')
                this.$router.push('/')
            },
            settingsPage() {
                this.$router.push('/settings')
            },
            openDropdown() {
                this.$refs.drop.click()
            }
        }
    }
</script>

<style scoped lang="scss">
    .header {
        &_container {
            width: 100%;
            /*height: 96px;*/
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-bottom: 7px;
            border-bottom: inset 1px #bfbfbf;
            position: sticky;
            top: 0;
            z-index: 5;
        }

        &_content {
            width: 100%;
            height: 96px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            background: #FFFFFF;


        }

        &_user {
            &-data {
                padding: 0 60px;
                display: flex;
                align-items: center;
                @media screen and (max-width: 1024px) {
                    padding: 0 30px;
                }
                ::v-deep ul {
                    border-radius: 0 !important;
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    border: none;
                }

                & .arrow_down {
                    width: 12px;
                    height: 6px;
                    background: url("~@/assets/images/arrow_down.png") no-repeat center;
                    background-size: cover;
                }

                ::v-deep .dropdown {
                    &:focus {
                        outline: none;
                        box-shadow: none;
                        border: none;
                    }

                    ::v-deep .dropdown-menu {
                        left: -60px !important;
                        top: 15px !important;
                    }
                    &-toggle {
                        &:focus-visible {
                            box-shadow: none;
                        }
                    }
                }
            }

            &-img {
                margin: 0 20px;

                & img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    object-fit: cover;
                }
            }
            &-name {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                cursor: pointer;
            }
        }
    }
</style>
