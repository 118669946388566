<template>
    <div class="main_container">
        <LeftBar/>
        <div class="page_content">
            <Header/>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import LeftBar from "../LeftBar";
    import Header from "../Header";

    export default {
        name: "Dashboard",
        components: {
            LeftBar,
            Header
        }
    }
</script>

<style scoped lang="scss">
    .main_container {
        display: flex;
        background: #F8F8F8;
        min-height: 100vh;
        width: 100vw;
        height: 100%;
        position: relative;
        padding-left: 96px;
    }

    .page_content {
        width: 100%;
        padding-left: 28px;
        /*margin-left: 27px;*/
    }
</style>
