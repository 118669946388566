import Vue from 'vue'
import VueRouter from 'vue-router'
import store from "@/store"
import Instagram from '../views/Instagram.vue'
import SignIn from "../components/Auth/SignIn";
import SignUp from "../components/Auth/SignUp";
import ForgotChangePassword from "../components/Auth/ForgotChangePassword";
import NewCampaign from "../views/NewCampaign";
import CampaignView from "../views/CampaignView";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    redirect: '/login'
  },
  {
    path: "/login",
    name: "SignIn",
    component: SignIn,
    meta: {auth: false}
  },
  {
    path: "/register",
    name: "SignUp",
    component: SignUp,
    meta: {auth: false}
  },
  {
    path: "/forgot_password",
    name: "ForgotChangePassword",
    component: ForgotChangePassword,
    meta: {auth: false}
  },
  {
    path: '/instagram',
    name: 'Instagram',
    component: Instagram,
    meta: {layout: 'dashboard', auth: true},
  },
  {
    path: '/new_campaign',
    name: 'NewCampaign',
    component: NewCampaign,
    meta: {layout: 'dashboard', auth: true},
  },
  {
    path: '/campaign_view',
    name: 'CampaignView',
    component: CampaignView,
    meta: {layout: 'dashboard', auth: true},
    props: true
  },
  {
    path: '/settings',
    name: 'Settings',
    component: function () {
      return import(/* webpackChunkName: "settings" */ '../views/Settings.vue')
    },
    meta: {layout: 'dashboard', auth: true},
  },
  {
    path: '/campaign',
    name: 'Campaign',
    component: function () {
      return import(/* webpackChunkName: "campaign" */ '../views/Campaign.vue')
    },
    meta: {layout: 'dashboard', auth: true},
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // let user = JSON.parse(localStorage.getItem('logInUser'))
  if(to.meta.auth && !store.getters['accessToken']) {
    return next({name: 'SignIn'})
  }
  if(to.fullPath == '/login' && store.getters['accessToken']) {
    return next({name: 'Instagram'})
  }
  return next()
});

export default router
