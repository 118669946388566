<template>
    <div class="page_container">
        <div class="page_header">
            <div class="page_header-home hidden"></div>
            <a href="https://www.outbond.ai">
                <div class="page_header-logo"></div>
            </a>
            <a href="https://www.outbond.ai">
                <div class="page_header-home"></div>
            </a>
        </div>
        <div class="page_content">
            <div class="page_box">
                <div class="page_box-title">Sign up</div>

                <form @submit.prevent="signUpUser()">
                    <div class="input_item">
                        <label>Name</label>
                        <b-form-input
                                v-model="userForm.fullName"
                                type="text"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.fullName.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.fullName.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.fullName.required">Name field is required</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                id="email"
                                v-model="userForm.email"
                                type="email"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">Email field is required</span>
                            <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Password</label>
                        <b-form-input
                                :type="r_pass"
                                v-model="userForm.password"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text'}"
                             @click="showPassword('r_pass')"></div>
                        <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.password.required">Password field is required</span>
                            <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Confirm Password</label>
                        <b-form-input
                                :type="r_confirm"
                                v-model="userForm.password_confirmation"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password_confirmation.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': r_confirm == 'password', 'eye_open': r_confirm == 'text',}"
                             @click="showPassword('r_confirm')"></div>
                        <div v-if="isSubmitted && $v.userForm.password_confirmation.$error"
                             class="invalid-feedback">
                            <span v-if="!$v.userForm.password_confirmation.required">Confirm Password field is required</span>
                            <span v-else-if="!$v.userForm.password_confirmation.sameAsPassword">Passwords must match</span>
                        </div>
                    </div>

                    <b-button class="sign_btn" type="submit">Sign up</b-button>

                    <div class="have_account">Don’t have an account? <router-link to="/login"><div class="sign_text">&nbsp; Login</div></router-link></div>
                </form>


            </div>
        </div>
    </div>
</template>

<script>
    import { required, email, minLength, sameAs } from "vuelidate/lib/validators"
    import { mapActions } from "vuex";
    export default {
        name: "SignUp",
        data() {
            return {
                userForm: {
                    fullName: "",
                    email: "",
                    password: "",
                    password_confirmation: "",
                },
                isSubmitted: false,
                r_pass: "password",
                r_confirm: "password",
                success_msg: '',
                error_msg: null

            }
        },
        validations: {
            userForm: {
                fullName: {
                    required
                },
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs("password")
                }
            }
        },

        methods: {
            ...mapActions(['registerUser']),
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === "password") {
                        this.r_pass = "text"
                    } else {
                        this.r_pass = "password"
                    }
                } else {
                    if (this.r_confirm === "password") {
                        this.r_confirm = "text"
                    } else {
                        this.r_confirm = "password"
                    }
                }
            },
            signUpUser() {
                this.isSubmitted = true;
                this.$v.$touch();
                if(this.$v.$invalid) {
                    return
                } else {
                    this.error_msg = null
                    this.registerUser(this.userForm)
                    .then((res) => {
                        if(res.status == 200) {
                            // this.success_msg = res.data.success_message
                            this.$router.push({path: "/instagram"})
                        }
                    })
                }

            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            padding: 50px 54px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
        &_header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-home {
                width: 32px;
                height: 32px;
                background: url("~@/assets/images/Home_light.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                &.hidden {
                    opacity: 0;
                    visibility: hidden;
                }
            }
            &-logo {
                width: 250px;
                height: 50px;
                background: url("~@/assets/images/logo_text.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
        &_content {
            display: flex;
            width: 100%;
            height: calc(100vh - 160px);
            justify-content: center;
            align-items: center;
        }
        &_box {
            width: 520px;
            background: #fff;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            padding: 40px;
            &-title {
                text-align: center;
                font-size: 40px;
                font-weight: 500;
                line-height: 52px;
                margin-bottom: 20px;
            }
            & form {
                padding: 0 40px;
            }
            & .input_item {
                margin-bottom: 10px;
                position: relative;
                & label {
                    color: #9E9E9E;
                    font-weight: 500;
                    line-height: 21px;
                }
                & input {
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    &:focus {
                        border: 1px solid rgba(24, 144, 255, 0.3);
                        box-shadow: none;
                    }
                }
                & .eye_close {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 15px;
                    top: 38px;
                    background: url("~@/assets/images/eye_open.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .eye_open {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 15px;
                    top: 38px;
                    background: url("~@/assets/images/eye_close.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }
            }
            & .forgot_pass {
                color: #8103F6;
                line-height: 20px;

            }
            & .sign_btn{
                margin: 20px auto;
                text-align: center;
                display: flex;
                justify-content: center;
                padding: 14px 0;
                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                border-radius: 10px;
                border: none;
                width: 130px;
                height: 50px;
                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }
                &:focus {
                    background: #8103F6;
                }
            }
            & .have_account {
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 500;
                & .sign_text {
                    color: #8103F6;
                }
            }
        }
    }
    ::v-deep .is-invalid {
        background-image: none !important;
        border: 1px solid rgba(255, 6, 6, 0.6) !important;
    }
</style>
