<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
<!--    <LeftBar />-->
<!--    <div class="page_content">-->
<!--      <Header />-->
<!--      <router-view/>-->
<!--    </div>-->
  </div>
</template>

<script>
  import Header from "./components/Header";
  import LeftBar from "./components/LeftBar";
  export default {
    components: {
      LeftBar,
      Header
    },
    computed: {
      layout() {
        return this.$route.meta.layout ? `${this.$route.meta.layout}-layout` : 'default-layout'
      },
    }
  }
</script>

<style lang="scss">
  @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'DM Sans', sans-serif;
  }
#app {
  display: flex;
  background: #F8F8F8;
  width: 100vw;
  /*height: 100vh;*/
  a {
    text-decoration: none;
  }

}
  .text_danger {
    font-size: 0.875em;
    color: #dc3545;
  }
/*  .page_content {*/
/*    width: 100%;*/
/*    margin-left: 27px;*/
/*  }*/
</style>

