<template>
    <div class="instagram_account">
        <div class="instagram_account-head">
            <div>
                <div class="instagram_account-title">Instagram Accounts</div>
                <p class="instagram_account-description">Lorem ipsum dolor sit amet consectetur. Risus varius at egestas
                    pretium viverra placerat iaculis posuere</p>
            </div>
            <div>
                <b-button class="add_btn" @click="addAccount()">+ Add new</b-button>
            </div>
        </div>
        <div class="page_content" v-if="!accounts.length">
            <div class="page_content-img"></div>
            <div class="page_content-title">Lorem ipsum dolor sit amet consectetur.</div>
            <div class="page_content-description">Lorem ipsum dolor sit amet consectetur. Arcu vulputate et cursus
                commodo porttitor.
            </div>
            <b-button class="page_content-add_btn" @click="addAccount()">+ Add new</b-button>
        </div>
        <div class="accounts_content">
            <div class="accounts_item" v-for="item of accounts">
                <div class="accounts_item-user">
                    <div class="accounts_item-img" :style="{ backgroundImage: 'url(' + item.media_url + ')' }">
                        <div class="instagram_logo"></div>
                    </div>
                    <div class="accounts_item-name">{{item.user_name}}</div>
                </div>
                <div class="accounts_item-switch">
                    <b-form-checkbox switch size="lg"></b-form-checkbox>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    // @ is an alias to /src

    import {mapActions} from "vuex";

    export default {
        name: 'Instagram',
        data() {
            return {
                accounts: []
            }
        },
        created() {
            if (this.getUrl) {
                this.getAccount(this.getUrl)
                    .then(res => {
                        if (res.status == 200) {
                            // setTimeout(() => {
                            //     var url = document.location.href
                            //     document.location.href = url.split('?')[0]
                            // }, 2000)
                            // this.accounts.push({
                            //     username: res.data.username,
                            //     status: 'active',
                            //     avatar: res.data.media_url,
                            // })
                            // this.$router.push('/instagram')
                        }
                    })
            } else {
                setTimeout(() => {
                    this.getAllAccounts()
                        .then(res => {
                            this.accounts = res.data
                        })
                }, 500)
            }
        },
        computed: {
            getUrl() {
                return this.$route.query.code
            },
        },
        mounted() {

            // this.$router.push('/instagram')
        },
        methods: {
            ...mapActions(['getAccount', 'getAllAccounts']),
            addAccount() {
                const url = 'https://www.instagram.com/oauth/authorize?client_id=4308561339368372&redirect_uri=https%3A%2F%2Fid5800.freelancedeveloper.site%2Finstagram&scope=user_profile%2Cuser_media&response_type=code&logger_id=051e12f3-0411-4710-9e5e-feba461029cf';
                const link = document.createElement('a');
                link.href = url;
                document.body.appendChild(link);
                link.click();
            }
        }

    }
</script>
<style scoped lang="scss">
    .instagram {
        &_account {
            padding: 8px 0;

            & .page_content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                width: 100%;
                height: 100%;
                padding: 70px 0;

                &-img {
                    width: 360px;
                    height: 290px;
                    background: url("~@/assets/images/page_graphic.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                }

                &-title {
                    padding: 32px 0 16px;
                    font-weight: 500;
                    font-size: 40px;
                    line-height: 52px;
                    text-align: center;
                    color: #000;
                    max-width: 600px;

                }

                &-description {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #393939;
                    opacity: 0.4;
                    padding-bottom: 16px;
                    max-width: 450px;
                }

                &-add_btn {
                    width: 130px;
                    height: 50px;
                    background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                    border-radius: 10px;
                    border: none;

                    &:hover {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }

                    &:focus {
                        background: #8103F6;
                    }
                }
            }

            & .accounts {
                &_content {
                    max-width: 900px;
                    display: flex;
                    flex-wrap: wrap;
                    @media screen and (max-width: 1024px) {
                        max-width: 870px;
                    }
                }

                &_item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 414px;
                    height: 96px;
                    background: #FFFFFF;
                    border: 1px solid #E7E7E7;
                    border-radius: 8px;
                    margin: 0 20px 20px 0;
                    padding: 10px;

                    &-user {
                        display: flex;
                        align-items: center;
                    }

                    &-img {
                        position: relative;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        background: url("~@/assets/images/user_empty_img.svg");
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: contain;

                        & .instagram_logo {
                            position: absolute;
                            bottom: 0;
                            right: -6px;
                            width: 24px;
                            height: 24px;
                            background: url("~@/assets/images/instagram_icon.svg");
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }

                    &-name {
                        padding: 0 20px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000;
                    }
                }
            }

            &-head {
                padding-right: 100px;
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 1024px) {
                    padding-right: 30px;
                }

                & .add_btn {
                    width: 130px;
                    height: 50px;
                    background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                    border-radius: 10px;
                    border: none;

                    &:hover {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }

                    &:focus {
                        background: #8103F6;
                    }
                }
            }

            &-title {
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #000;
                margin-bottom: 8px;
            }

            &-description {
                max-width: 456px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #393939;
            }
        }
    }

</style>
