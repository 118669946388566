<template>
    <div class="page_container">
        <div class="page_head">
            <router-link to="/campaign">
                <div class="back_btn">
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 13.0586L2 7.03909L8 1.01954" stroke="#333333" stroke-width="2"/>
                    </svg>
                    Back
                </div>
            </router-link>
        </div>
        <div class="page_content">
            <h1 class="page_content-title">Let’s create a new campaign</h1>
            <div class="input_item">
                <b-form-input
                        v-model="new_campaign"
                        placeholder="Campaign name"
                        @keyup.enter="continueCampaign()"
                >
                </b-form-input>
            </div>
            <div class="input_text">What would you like to name it?</div>
            <div class="btn_container">
                <b-button class="back_btn" @click="$router.push('/campaign')">Back</b-button>
                <b-button :disabled="new_campaign == null || new_campaign == ''" class="continue_btn"
                          @click="continueCampaign()">Continue
                </b-button>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "NewCampaign",
        data() {
            return {
                new_campaign: null
            }
        },
        methods: {
            continueCampaign() {
                this.$store.state.campaignName = this.new_campaign
                localStorage.setItem('campaignName', this.new_campaign)
                this.$router.push({
                    name: "CampaignView"
                });
            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            padding: 8px 0;
            display: flex;
            flex-direction: column;
        }

        &_head {
            padding-right: 100px;

            & .back_btn {
                padding: 0 5px;
                font-weight: 500;
                font-size: 20px;
                line-height: 26px;
                color: #000000;
            }
        }

        &_content {
            padding: 80px 100px 10px 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &-title {
                font-weight: 500;
                font-size: 40px;
                line-height: 52px;
                text-align: center;
                color: #000000;
            }

            & .input_item {
                padding: 16px 0;

                & input {
                    width: 470px;
                    height: 44px;
                    outline: none;
                    line-height: 24px;
                    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
                    background: transparent;

                    &::placeholder {
                        /*color: #393939;*/
                        /*line-height: 24px;*/
                    }
                }
            }

            & .input_text {
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #393939;
                opacity: 0.4;

            }

            & .btn_container {
                padding: 16px 0;
                max-width: 280px;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                & .back_btn {
                    width: 130px;
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid #393939;
                    background: transparent;
                    color: #393939;;

                    &:hover {
                        background: #ffffff;
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }

                    &:focus {
                        background: #ffffff;
                    }

                }

                & .continue_btn {
                    width: 130px;
                    height: 50px;
                    background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                    border-radius: 10px;
                    border: none;

                    &:hover {
                        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                    }

                    &:focus {
                        background: #8103F6;
                    }
                }
            }
        }
    }
</style>
