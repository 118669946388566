<template>
    <div class="page_container">
        <div class="page_header">
            <div class="page_header-home hidden"></div>
            <a href="https://www.outbond.ai">
                <div class="page_header-logo"></div>
            </a>
            <a href="https://www.outbond.ai">
                <div class="page_header-home"></div>
            </a>
        </div>
        <div class="page_content">
            <div class="page_box">
                <div class="page_box-title">Log in</div>

                <form @submit.prevent="loginUser()">
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                id="email"
                                v-model="userForm.email"
                                type="email"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.email.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.userForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.email.required">Email field is required</span>
                            <span v-if="!$v.userForm.email.email">Please provide valid email</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Password</label>
                        <b-form-input
                                id="password"
                                :type="l_password"
                                v-model="userForm.password"
                                :class="{ 'is-invalid': isSubmitted && $v.userForm.password.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': l_password == 'password', 'eye_open': l_password == 'text',}"
                             @click="showPassword()"></div>
                        <div v-if="isSubmitted && $v.userForm.password.$error" class="invalid-feedback">
                            <span v-if="!$v.userForm.password.required">Password field is required</span>
                            <span v-if="!$v.userForm.password.minLength">Password should be at least 6 characters long</span>
                        </div>
                    </div>
                    <div v-if="loginError" class="text_danger">
                        <span>{{loginError}}</span>
                    </div>
                    <div class="forgot_container">
                        <router-link to="/forgot_password">
                            <div class="forgot_pass" id="forgot-btn" >Forgot password?</div>
                        </router-link>
                    </div>
                    <b-button class="sign_btn" type="submit">Log In</b-button>
                    <div class="have_account">Don’t have an account? <router-link to="/register"><div class="sign_text">&nbsp; Sign Up</div></router-link></div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, email, minLength} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";
    export default {
        name: "SignIn",
        data() {
            return {
                userForm: {
                    email: "",
                    password: ""
                },
                l_password: 'password',
                isSubmitted: false,
                loginError: null,
            }
        },
        validations: {
            userForm: {
                email: {
                    required,
                    email
                },
                password: {
                    required,
                    minLength: minLength(6)
                },
            }
        },
        methods: {
            ...mapActions(['login']),
            loginUser() {
                this.loginError = null
                this.isSubmitted = true;
                this.$v.$touch();
                if(this.$v.$invalid) {
                    return
                } else {
                    this.login(this.userForm)
                        .then((res) => {
                            if (res.status == 200) {
                                this.$router.push('/instagram')
                            } else {
                                this.loginError = res.response.data.error
                            }
                        })
                }
            },
            forgotPassword() {


            },
            showPassword() {
                if (this.l_password === 'password') {
                    this.l_password = 'text'
                } else {
                    this.l_password = 'password'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        padding: 50px 54px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
    }
    &_header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &-home {
            width: 32px;
            height: 32px;
            background: url("~@/assets/images/Home_light.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
            &.hidden {
                opacity: 0;
                visibility: hidden;
            }
        }
        &-logo {
            width: 250px;
            height: 50px;
            background: url("~@/assets/images/logo_text.png");
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &_content {
        display: flex;
        width: 100%;
        height: calc(100vh - 160px);
        justify-content: center;
        align-items: center;
    }
    &_box {
        width: 520px;
        background: #fff;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        padding: 40px;
        &-title {
            text-align: center;
            font-size: 40px;
            font-weight: 500;
            line-height: 52px;
            margin-bottom: 20px;
        }
        & form {
            padding: 0 40px;
        }
        & .input_item {
            margin-bottom: 10px;
            position: relative;
            & label {
                color: #9E9E9E;
                font-weight: 500;
                line-height: 21px;
            }
            & input {
                height: 50px;
                border-radius: 10px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                &:focus {
                    border: 1px solid rgba(24, 144, 255, 0.3);
                    box-shadow: none;
                }
            }
            & .eye_close {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 15px;
                top: 38px;
                background: url("~@/assets/images/eye_open.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }

            & .eye_open {
                position: absolute;
                width: 24px;
                height: 24px;
                right: 15px;
                top: 38px;
                background: url("~@/assets/images/eye_close.svg");
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                cursor: pointer;
            }
        }
        & .forgot_pass {
            color: #8103F6;
            line-height: 20px;

        }
        & .sign_btn{
            margin: 20px auto;
            text-align: center;
            display: flex;
            justify-content: center;
            padding: 14px 0;
            background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
            border-radius: 10px;
            border: none;
            width: 130px;
            height: 50px;
            &:hover {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
            }
            &:focus {
                background: #8103F6;
            }
        }
        & .have_account {
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
            & .sign_text {
                color: #8103F6;
            }
        }
    }
}
::v-deep .is-invalid {
    background-image: none !important;
    border: 1px solid rgba(255, 6, 6, 0.6) !important;
}
</style>
