<template>
    <div class="main_content">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: "Default"
    }
</script>

<style scoped lang="scss">
.main_content {
    width: 100vw;
    height: 100vh;
}
</style>
