import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        baseURL: process.env.VUE_APP_BASE_URL,
        access_token: localStorage.getItem('access_token') || '',
        logInUser: {},
        campaignName: localStorage.getItem('campaignName') || ''
    },
    mutations: {
        clearUserData: function (state) {
            state.access_token = "";
            state.logInUser = {}
            localStorage.removeItem('access_token')
            localStorage.removeItem('logInUser')
        },
        addUserData: (state, data) => {
            state.logInUser = data
        },

    },
    actions: {
        registerUser({state, commit}, data) {
            return axios.post(`${state.baseURL}register`, {
                    name: data.fullName,
                    email: data.email,
                    password: data.password,
                    c_password: data.password_confirmation,
                }
            )
                .then((response) => {
                    if (response) {
                        // let access_token = response.data.token
                        // localStorage.setItem('access_token', access_token)
                        // state.access_token = access_token
                        // state.logInUser = response.data.user
                        // let user = JSON.stringify(response.data.user)
                        // localStorage.setItem('logInUser', user)
                        // commit('addUserData', user)
                        return response
                    }
                })
                .catch((error) => {
                    return error
                })
        },
        login({state, commit}, data) {
            return axios.post(`${state.baseURL}login`, {
                email: data.email,
                password: data.password,
            })
                .then((response) => {
                    if (response) {
                        let access_token = response.data.user.token
                        localStorage.setItem('access_token', access_token)
                        state.access_token = access_token
                        let userData = {
                            email: response.data.user.email,
                            name: response.data.user.name,
                        }
                        localStorage.setItem('logInUser', JSON.stringify(userData))
                        commit('addUserData', userData)
                        return response
                    }
                })
                .catch((error) => {
                    console.log(error)
                    return error
                })
        },
        getUserData({state, commit}) {
            return axios.get(`${state.baseURL}user-details`)
                .then(response => {
                    let userData = {
                        email: response.data.email,
                        name: response.data.name,
                        avatar: response.data.avatar,
                        subscription_type: response.data.subscription_type
                    }
                    localStorage.setItem('logInUser', JSON.stringify(userData))
                    commit('addUserData', userData)
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        changeUserPass({state}, data) {
            return axios.post(`${state.baseURL}change-password`, {
                old_password: data.oldPassword,
                new_password: data.newPassword,
                c_password: data.confirmNewPassword
            })
                .then(response => {
                    return response
                })
                .catch((error) => {

                    return error
                })
        },
        changePersonalDetails({state}, data) {
            let formData = new FormData()
            let avatar = null
            if (data.changeAvatar) {
                avatar = data.changeAvatar
                formData.append('avatar', avatar)
            } else if (data.avatar == null && data.changeAvatar == null) {
                formData.append('avatar', null)
            }
            formData.append('name', data.fullName)
            return axios.post(`${state.baseURL}edit-user-profile`, formData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        forgotPass({state}, data) {
            return axios.post(`${state.baseURL}password/email`, {
                email: data.email
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        sendEmailCode({state}, data) {
            return axios.post(`${state.baseURL}password/code/check`, {
                code: data
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        resetPassword({state}, data) {
            return axios.post(`${state.baseURL}password/reset`, {
                code: data.code,
                password: data.new_password,
                c_password: data.confirm_new_password
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAccount({state}, data) {
            return axios.get(`${state.baseURL}instagram-auth/${data}`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAllAccounts({state}) {
            return axios.get(`${state.baseURL}get-instagram-accounts`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        addVisaCard({state}, data) {
            return axios.post(`${state.baseURL}`, {})
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getAllLeads({state}) {
            return axios.get(`${state.baseURL}get-leads`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        uploadCSV({state}, data) {
            let formData = new FormData()
            formData.append('csv', data)
            formData.append('campaign_id', 1)
            return axios.post(`${state.baseURL}add-leads`, formData)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        addNewCampaign({state}, data) {
            return axios.post(`${state.baseURL}add-campaign`, {
                instagram_account_id: data.account_id,
                name: data.campaign_name,
                date_for_picking: data.date,
                delay_between_messages: data.per_day,
                day: data.selectedDays
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getCampaigns({state}) {
            return axios.get(`${state.baseURL}get-campaigns`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        getTemplates({state}) {
            return axios.get(`${state.baseURL}get-templates`)
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        },
        removeTemplate({state}, data) {
          return axios.delete(`${state.baseURL}delete-template/${data}`)
              .then(response => {
                  return response
              })
              .catch(error => {
                  return error
              })
        },
        updateTemplate({state}, data) {
          return axios.post(`${state.baseURL}update-template/${data.account_id}`, {
              subject: data.subject,
              wait_for: data.wait_for,
              text: data.text,
          })
        },
        addNewTemplate({state}, data) {
            return axios.post(`${state.baseURL}add-template`, {
                subject: data.subject,
                wait_for: data.wait_for,
                text: data.text,
                account_id: data.account_id
            })
                .then(response => {
                    return response
                })
                .catch(error => {
                    return error
                })
        }
    },
    getters: {
        accessToken: state => state.access_token,
        logUserData: state => state.logInUser,
        campaignName: state => state.campaignName,

    },
    modules: {}
})
