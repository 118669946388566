<template>
    <div class="page_container">
        <div class="page_header">
            <div class="page_header-home hidden"></div>
            <a href="https://www.outbond.ai">
                <div class="page_header-logo"></div>
            </a>
            <a href="https://www.outbond.ai">
                <div class="page_header-home"></div>
            </a>
        </div>
        <div class="page_content">
            <div v-if="!forgotStatus && !checkCode" class="page_box">
                <div class="page_box-title">Forgot password?</div>
                <div class="page_box-subtitle">Enter your registered email below to receive password reset istruction
                </div>
                <div class="page_box-img_container">
                    <div class="page_box-img"></div>
                </div>
                <div class="input_item-container">
                    <div class="input_item">
                        <label>Email</label>
                        <b-form-input
                                id="f_email"
                                v-model="forgotForm.email"
                                type="email"
                                :class="{ 'is-invalid': isSubmitted && $v.forgotForm.email.$error }"
                                trim
                        >
                        </b-form-input>
                        <div v-if="isSubmitted && $v.forgotForm.email.$error" class="invalid-feedback">
                            <span v-if="!$v.forgotForm.email.required">Email field is required</span>
                            <span v-if="!$v.forgotForm.email.email">Please provide valid email</span>
                        </div>
                    </div>
                </div>
                <b-button class="box_btn" @click="sendForgotEmail()">Send</b-button>
            </div>
            <div v-if="forgotStatus && checkCode" class="page_box">
                <div class="page_box-title">Email has been sent!</div>
                <div class="page_box-subtitle">Please check your inbox and click in the received email to reset a
                    password
                </div>
                <div class="page_box-img_container">
                    <div class="page_box-img"></div>
                </div>
            </div>
            <div v-if="!forgotStatus && checkCode" class="page_box">
                <div class="page_box-title">Please check your email for code</div>
                <div class="page_box-subtitle">Please enter the 6 digital code sent to {{getEmail}}</div>
                <div class="page_box-img_container">
                    <div class="page_box-img"></div>
                </div>
                <div class="input_item-container">
                    <div class="input_item">
                        <label class="ml-2">Enter code</label>
                        <div class="code_inputs">
                            <b-form-input
                                    v-for="(c, index) in ct"
                                    :key="index"
                                    type="number"
                                    v-model="ct[index]"
                                    ref="input"
                                    @input="e => {onInput(e.target.value, index)}"
                                    @keydown.delete="e => {onKeydown(e.target.value, index)}"
                                    @focus="onFocus"
                                    :disabled="loading"
                            ></b-form-input>
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code1"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code2"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code3"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code4"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code5"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
<!--                            <b-form-input-->
<!--                                    v-model="all_code.code6"-->
<!--                                    onKeyPress="if(this.value.length == 1) return false;"-->
<!--                                    type="number"-->
<!--                                    trim-->
<!--                            >-->
<!--                            </b-form-input>-->
                        </div>

                    </div>
                </div>
<!--                :disabled="!all_code.code1 ||-->
<!--                !all_code.code2 ||-->
<!--                !all_code.code3 ||-->
<!--                !all_code.code4 ||-->
<!--                !all_code.code5 ||-->
<!--                !all_code.code6"-->
                <b-button class="box_btn"
                          @click="sendCode()"

                >Continue
                </b-button>
            </div>
            <div v-if="forgotStatus && !checkCode" class="page_box">

                <div class="page_box-title">Create new password</div>
                <div class="page_box-subtitle">Please enter your new password</div>
                <div class="input_item-container">
                    <div class="input_item">
                        <label>New Password</label>
                      <b-form-input
                              v-model="new_pass.new_password"
                              :type="r_pass"
                              :class="{ 'is-invalid': isSubmitted && $v.new_pass.new_password.$error }"
                              trim
                      ></b-form-input>
                        <div :class="{'eye_close': r_pass == 'password', 'eye_open': r_pass == 'text'}"
                             @click="showPassword('r_pass')"></div>
                        <div v-if="isSubmitted && $v.new_pass.new_password.$error" class="invalid-feedback">
                            <span v-if="!$v.new_pass.new_password.required">New Password field is required</span>
                            <span v-if="!$v.new_pass.new_password.minLength">New Password should be at least 6 characters long</span>
                        </div>
                    </div>
                    <div class="input_item">
                        <label>Confirm New Password</label>
                        <b-form-input
                                v-model="new_pass.confirm_new_password"
                                :type="r_confirm"
                                :class="{ 'is-invalid': isSubmitted && $v.new_pass.confirm_new_password.$error }"
                                trim
                        ></b-form-input>
                        <div :class="{'eye_close': r_confirm == 'password', 'eye_open': r_confirm == 'text',}"
                             @click="showPassword('r_confirm')"></div>
                        <div v-if="isSubmitted && $v.new_pass.confirm_new_password.$error"
                             class="invalid-feedback">
                            <span v-if="!$v.new_pass.confirm_new_password.required">Confirm New Password field is required</span>
                            <span v-else-if="!$v.new_pass.confirm_new_password.sameAsPassword">Passwords must match</span>
                        </div>
                    </div>
                </div>
                <b-button class="box_btn"
                          @click="logIn()"

                >Login
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {required, email, minLength, sameAs} from "vuelidate/lib/validators";
    import {mapActions} from "vuex";

    export default {
        name: "ForgotChangePassword",
        data() {
            return {
                forgotForm: {
                    email: ''
                },
                isSubmitted: false,
                forgotStatus: false,
                checkCode: false,
                all_code: {
                    code1: null,
                    code2: null,
                    code3: null,
                    code4: null,
                    code5: null,
                    code6: null,
                },
                ct: ['','','','','',''],
                loading: false,
                msg: '',
                new_pass: {
                    code: null,
                    new_password: null,
                    confirm_new_password: null
                },
                code: '',
                r_pass: "password",
                r_confirm: "password",

            }
        },
        validations() {
            if(!this.forgotStatus && !this.checkCode) {
                return {
                    forgotForm: {
                        email: {
                            required,
                            email
                        }
                    },
                }
            } else {
                return  {
                    new_pass: {
                        new_password: {
                            required,
                            minLength: minLength(6)
                        },
                        confirm_new_password: {
                            required,
                            sameAsPassword: sameAs("new_password")
                        }
                    }
                }
            }


        },
        computed: {
            getEmail() {
                return this.forgotForm.email[0] + '***@' + this.forgotForm.email.split('@')[1]
            },
            ctSize() {
                return this.ct.length
            },
            cIndex() {
                let i = this.ct.findIndex(item => item === '')
                i = (i + this.ctSize)% this.ctSize
                return i
            },
            lastCode() {
                return this.ct[this.ctSize-1]
            }
        },
        watch: {
          cIndex() {
              this.resetCaret()
          },
          lastCode(val) {
              if(val) {
                  this.$refs.input[this.ctSize-1].blur()
                  this.sendCode()
              }
          }
        },
        // mounted() {
        //     this.resetCaret()
        // },
        methods: {
            ...mapActions(['forgotPass', 'sendEmailCode', 'resetPassword']),
            showPassword(item) {
                if (item === 'r_pass') {
                    if (this.r_pass === "password") {
                        this.r_pass = "text"
                    } else {
                        this.r_pass = "password"
                    }
                } else {
                    if (this.r_confirm === "password") {
                        this.r_confirm = "text"
                    } else {
                        this.r_confirm = "password"
                    }
                }
            },
            sendForgotEmail() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                } else {
                    this.forgotPass(this.forgotForm)
                        .then(res => {
                            if (res.status == 200) {
                                this.forgotStatus = true
                                this.checkCode = true
                                setTimeout(() => {
                                    this.forgotStatus = false
                                }, 3000)
                            }
                        })
                }

            },
            sendCode() {
                this.new_pass.code = (this.ct.join(''))
              //   for (const property in this.all_code) {
              //       code += this.all_code[property]
              //   }
              this.sendEmailCode(this.new_pass.code)
                .then(res => {
                    if(res.status == 200) {
                        this.forgotStatus = true
                        this.checkCode = false
                    }
                })
            },

            onInput(val, index) {
                this.msg = ''
                val = val.replace(/\s/g,'')
                if(index == this.ctSize-1) {
                    this.ct[this.ctSize-1] = val[0]
                } else if(val.length > 1) {
                    let i = index
                    for (i = index; i < this.ctSize && i-index < val.length; i++) {
                        this.ct[i] = val[i]
                    }
                    this.resetCaret()
                }
            },
            resetCaret() {
                this.$refs.input[this.ctSize-1].focus()
            },
            onFocus() {
                let index = this.ct.findIndex(item => item === '')
                index = (index + this.ctSize)% this.ctSize
                this.$refs.input[index].focus()
            },
            onKeydown(val, index) {
                if(val === '') {
                    if(index > 0) {
                        this.ct[index - 1] = ''
                        this.$refs.input[index - 1].focus()
                    }
                }
            },
            logIn() {
                this.isSubmitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return
                } else {
                    this.resetPassword(this.new_pass)
                        .then(res => {
                            if(res.status == 200) {
                                this.$router.push('/login')
                            }
                        })
                }



            }
        }
    }
</script>

<style scoped lang="scss">
    .page {
        &_container {
            padding: 50px 54px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }

        &_header {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-home {
                width: 32px;
                height: 32px;
                background: url("~@/assets/images/Home_light.svg");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                &.hidden {
                    opacity: 0;
                    visibility: hidden;
                }
            }

            &-logo {
                width: 250px;
                height: 50px;
                background: url("~@/assets/images/logo_text.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }

        &_content {
            display: flex;
            width: 100%;
            height: calc(100vh - 160px);
            justify-content: center;
            align-items: center;
        }

        &_box {
            width: 710px;
            background: #fff;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            padding: 40px;

            &-title {
                text-align: center;
                font-size: 40px;
                font-weight: 500;
                line-height: 52px;
                margin-bottom: 20px;
            }

            &-subtitle {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                color: #393939;
            }

            &-img {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background: url("~@/assets/images/message_circle.png");
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;

                &_container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 16px 0 45px;
                }
            }

            & .input_item {
                margin-bottom: 10px;
                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 100%;
                max-width: 500px;

                &-container {
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    padding: 32px 0 10px;
                }

                & .code_inputs {
                    display: flex;

                    & input {
                        width: 70px;
                        text-align: center;
                    }
                }

                & label {
                    color: #9E9E9E;
                    font-weight: 500;
                    line-height: 21px;
                }

                & input {
                    height: 50px;
                    border-radius: 10px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    max-width: 500px;
                    margin: 0 auto;

                    &:focus {
                        border: 1px solid rgba(24, 144, 255, 0.3);
                        box-shadow: none;
                    }
                }

                & .eye_close {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 15px;
                    top: 38px;
                    background: url("~@/assets/images/eye_open.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }

                & .eye_open {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    right: 15px;
                    top: 38px;
                    background: url("~@/assets/images/eye_close.svg");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    cursor: pointer;
                }
            }

            & .box_btn {
                margin: 20px auto;
                text-align: center;
                display: flex;
                justify-content: center;
                padding: 14px 0;
                background: linear-gradient(90deg, #8103F6 -321.88%, #C133FF 212.01%, #DF02FE 684.13%);
                border-radius: 10px;
                border: none;
                width: 130px;
                height: 50px;

                &:hover {
                    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                }

                &:focus {
                    background: #8103F6;
                    box-shadow: none;
                }
            }
        }
    }

    ::v-deep .is-invalid {
        background-image: none !important;
        border: 1px solid rgba(255, 6, 6, 0.6) !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }
</style>
